'use client'
import Image from 'next/image'
import bigLogo from '../../../../public/img/gratialogo_horizontal_color.png'
import { useQueryState } from 'nuqs'
import UserSignIn from './UserSignIn'
import AdminSignInForm from './AdminSignInForm'
import { useFlag } from '@unleash/nextjs'
import { Suspense } from 'react'

export default function SignInWrapper() {
  return (
    <Suspense>
      <SignIn />
    </Suspense>
  )
}

function SignIn() {
  const [isAdmin] = useQueryState('isAdmin')
  const signInTechnicalDifficultiesBanner = useFlag('SignInTechnicalDifficultiesBanner')

  return (
    <div className="h-screen w-full bg-gradient-to-tl from-bright-blue-10 to-bright-blue-50 px-4 py-8">
      <div className="flex h-full flex-col">
        <div className="flex flex-col items-center py-2">
          <Image src={bigLogo} priority alt="Gratia logo" width={250} className="h-auto" />
        </div>

        <div className="mx-auto my-auto w-full max-w-sm rounded-xl bg-white p-10 shadow md:w-1/2 lg:w-1/3">
          {signInTechnicalDifficultiesBanner && (
            <div className="mb-4 flex justify-center rounded-md border-2 border-black-100 bg-yellow-light p-2">
              Some of our Users are unable to login right now. We are aware of the problem and are working quickly to
              fix it. Please try again later.
            </div>
          )}
          <h3 aria-label="Sign in to your account" className="text-center font-bold leading-tight text-gray-700">
            Sign in to your account
          </h3>
          <Suspense>{isAdmin ? <AdminSignInForm /> : <UserSignIn />}</Suspense>
        </div>
      </div>
    </div>
  )
}
